<template>
  <div v-loading="loading">
    <div class="side-highlight-container">
      <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
        <BaseElFormItem :label="displayText.name" prop="name">
          <BaseElInput v-model="formData.name" />
        </BaseElFormItem>
        <BaseElFormItem :label="displayText.type" prop="type">
          <BaseElSelect v-model="formData.type">
            <BaseElSelectOption
              v-for="option in targetTypeConfig"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </BaseElSelect>
        </BaseElFormItem>

        <GrayBlockContainer>
          <div>
            <div class="flex">
              {{ displayText.selectTarget.title }} <span>( {{ displayText.selectTarget.desc }} )</span>
              <TipInfo width="200">
                <div v-html="displayText.selectTarget.tooltip" />
              </TipInfo>
            </div>
            <BaseElFormItem :label="displayText.service" prop="servicesHolder">
              <BaseElSelect
                v-model="formData.servicesHolder"
                clearable
                multiple
                collapse-tags
                :empty-text="displayText.emptyText"
                popper-class="select-popper"
                value-key="id"
                @visible-change="showSelector.service = true"
                @change="$emit('update')"
                @clear="onCategoryServiceClear"
              >
                <BaseElSelectOption
                  v-for="service in formData.servicesHolder"
                  :key="service.id"
                  :label="service.name"
                  :value="service"
                />
              </BaseElSelect>
            </BaseElFormItem>
            <BaseElFormItem v-if="useSubService" :label="displayText.subService">
              <BaseElSelect
                v-model="formData.subServicesHolder"
                clearable
                multiple
                collapse-tags
                :empty-text="displayText.emptyText"
                popper-class="select-popper"
                value-key="id"
                @visible-change="showSelector.subService = true"
                @change="$emit('update')"
                @clear="formData.subServicesHolder = []"
              >
                <BaseElSelectOption
                  v-for="service in formData.subServicesHolder"
                  :key="service.id"
                  :label="service.name"
                  :value="service"
                />
              </BaseElSelect>
            </BaseElFormItem>
            <BaseElFormItem :label="displayText.attachService">
              <BaseElSelect
                v-model="formData.attachServicesHolder"
                clearable
                multiple
                collapse-tags
                :empty-text="displayText.emptyText"
                popper-class="select-popper"
                value-key="id"
                @visible-change="showSelector.attachService = true"
                @change="$emit('update')"
                @clear="formData.attachServicesHolder = []"
              >
                <BaseElSelectOption
                  v-for="service in formData.attachServicesHolder"
                  :key="service.id"
                  :label="service.name"
                  :value="service"
                />
              </BaseElSelect>
            </BaseElFormItem>
          </div>
        </GrayBlockContainer>
      </BaseElForm>
    </div>

    <CategoryServiceAdvanceSelector
      v-if="showSelector.service"
      :data="categoryServiceDataList"
      :syncCompose="syncComposeServiceData"
      @close="showSelector.service = false"
      @confirm="formData.servicesHolder = $event"
      @compose="onCategoryServiceCompose"
    />
    <SubServiceAdvanceSelector
      v-if="showSelector.subService"
      :data="serviceDataList"
      :syncData="formData.subServicesHolder"
      @close="showSelector.subService = false"
      @confirm="formData.subServicesHolder = $event"
    />
    <AttachServiceAdvanceSelector
      v-if="showSelector.attachService"
      :data="attachServiceDataList"
      :syncData="formData.attachServicesHolder"
      @close="showSelector.attachService = false"
      @confirm="formData.attachServicesHolder = $event"
    />

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="onCancel"
      @confirm="onSubmit"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, reactive, set, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { targetTypeConfig } from '@/config/progressNotification'
// components
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import SubServiceAdvanceSelector from '@/components/Select/SubServiceAdvanceSelector.vue'
import CategoryServiceAdvanceSelector from '@/components/Select/CategoryServiceAdvanceSelector.vue'
import AttachServiceAdvanceSelector from '@/components/Select/AttachServiceAdvanceSelector.vue'
import TipInfo from '@/components/TipInfo.vue'
// api
import {
  FindProgressNotificationTargetTemplate,
  CreateProgressNotificationTargetTemplate,
  UpdateProgressNotificationTargetTemplate,
  GetNotifyTargetTemplateAppointmentConfigOptions,
} from '@/api/progressNotification'
// utils
import { noEmptyRules } from '@/validation'
import { get, map, isEmpty, find, filter, flatten, uniq } from 'lodash'
// use
import { useBaseForm, onFormRulesChangeClearValidate, mappingSyncFormData } from '@/use/useForm'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import store from '@/store'
import { checkUserFeature } from '@/store/modules/permission'

export default defineComponent({
  name: 'NotificationTargetTemplateEdit',
  components: { PageFixedFooter, GrayBlockContainer, TipInfo, CategoryServiceAdvanceSelector, SubServiceAdvanceSelector, AttachServiceAdvanceSelector },
  // emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const { simpleFetch } = useFetch()
    const { initFormData, formData, formRef, loading, formSubmit } = useBaseForm()

    const userPlanFeature = computed(() => get(store.getters, 'userPlanFeature'))
    const userFeatures = computed(() => get(store.getters, 'userFeatures'))
    const checkAction = (action) => {
      return checkUserFeature(userPlanFeature.value, userFeatures.value, action)
    }
    const useSubService = computed(() => checkAction('admin.appointmentSubService.edit'))
    const showSelector = reactive({
      service: false,
      subService: false,
      attachService: false,
    })
    const filterRemovedServices = (dataList) => {
      return filter(uniq(dataList), service => {
        if (service.type === 'service' && service.category) return service
        else if (service.type === 'category') return service
      })
    }
    const syncComposeServiceData = computed(() => {
      return {
        activeCategorys: filterRemovedServices([...formData.serviceSettings.categoryIds]),
        activeServices: filterRemovedServices([...formData.serviceSettings.includeServiceIds]),
        deActiveServices: filterRemovedServices([...formData.serviceSettings.excludeServiceIds]),
      }
    })

    const targetId = computed(() => get(route.params, 'id'))
    const displayText = computed(() => {
      const data = {
        name: '範本名稱',
        type: '通知對象類型',
        selectTarget: {
          title: '選擇通知對象',
          desc: '下列對象均會收到通知',
          tooltip: '您所選擇的所有項目選項，只要滿足任何一個項目，就會收到通知。<br><br>舉例：您設定了服務項目A、服務項目B、附加服務C。只要客人預約任何一個項目即可收到通知，無論是否有加選附加服務。因此建議同類型服務項目間可忽略附加服務的選擇。<br><br>欲針對特定附加服務發送通知，請單獨設置對應的範本。',
        },
        service: '服務項目',
        subService: '子項目',
        attachService: '附加服務',
        emptyText: '暫無數據',
      }
      return data
    })
    initFormData({
      name: null,
      type: null,
      servicesHolder: [],
      subServicesHolder: [],
      attachServicesHolder: [],

      serviceSettings: {
        includeServiceIds: [],
        excludeServiceIds: [],
        categoryIds: [],
      },
      subServiceSettings: {
        subServiceIds: [],
      },
      serviceAttachSettings: {
        serviceAttachIds: [],
      },
    })

    // 表單欄位規則
    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        type: [noEmptyRules()],
        servicesHolder: [noEmptyRules()],
      }
      return rules
    })

    const submitData = computed(() => {
      const data = {
        serviceSettings: null,
        subServiceSettings: null,
        serviceAttachSettings: null,
      }
      if (!isEmpty(formData.servicesHolder)) {
        data.serviceSettings = {
          includeServiceIds: uniq(map(formData.serviceSettings.includeServiceIds, 'id')),
          excludeServiceIds: uniq(map(formData.serviceSettings.excludeServiceIds, 'id')),
          categoryIds: uniq(map(formData.serviceSettings.categoryIds, 'id')),
        }
      }
      if (!isEmpty(formData.subServicesHolder)) {
        data.subServiceSettings = {
          subServiceIds: uniq(map(formData.subServicesHolder, 'id')),
        }
      }
      if (!isEmpty(formData.attachServicesHolder)) {
        data.serviceAttachSettings = {
          serviceAttachIds: uniq(map(formData.attachServicesHolder, 'id')),
        }
      }
      return {
        shopId: shopId.value,
        id: targetId.value,
        name: formData.name,
        type: formData.type,
        appointmentConfig: data,
      }
    })

    // 同步表單資料
    const syncFormData = (data) => {
      formData.name = data.name
      formData.type = data.type

      const serviceSettings = get(data, 'appointmentConfig.serviceSettings')
      const subServiceSettings = get(data, 'appointmentConfig.subServiceSettings')
      const serviceAttachSettings = get(data, 'appointmentConfig.serviceAttachSettings')

      const includeServices = map(get(serviceSettings, 'includeServiceIds'), serviceId => {
        return find(getDataByPath(categoryServiceDataList.value, 'services'), { id: serviceId }) || find(serviceDataList.value, { id: serviceId })
      }).filter(Boolean)
      const excludeServices = map(get(serviceSettings, 'excludeServiceIds'), serviceId => {
        return find(getDataByPath(categoryServiceDataList.value, 'services'), { id: serviceId })
      }).filter(Boolean)
      const category = map(get(serviceSettings, 'categoryIds'), serviceId => {
        return find(categoryServiceDataList.value, { id: serviceId })
      }).filter(Boolean)
      const subServices = map(get(subServiceSettings, 'subServiceIds'), serviceId => {
        return find(getDataByPath(serviceDataList.value, 'subServices'), { id: serviceId })
      }).filter(Boolean)
      const attachServices = map(get(serviceAttachSettings, 'serviceAttachIds'), serviceId => {
        return find(attachServiceDataList.value, { id: serviceId })
      }).filter(Boolean)
      console.log(includeServices, excludeServices, category, subServices, attachServices)
      // holder
      formData.servicesHolder = includeServices
      formData.subServicesHolder = subServices
      formData.attachServicesHolder = attachServices
      // category service
      formData.serviceSettings.includeServiceIds = includeServices
      formData.serviceSettings.excludeServiceIds = excludeServices
      formData.serviceSettings.categoryIds = category
      // sub service
      formData.subServiceSettings.subServiceIds = subServices
      // attach service
      formData.serviceAttachSettings.serviceAttachIds = attachServices
    }
    const onCategoryServiceClear = () => {
      set(formData, 'serviceSettings', {
        includeServiceIds: [],
        excludeServiceIds: [],
        categoryIds: [],
      })
    }

    const onCategoryServiceCompose = (composeData) => {
      set(formData.serviceSettings, 'includeServiceIds', composeData.serviceActive)
      set(formData.serviceSettings, 'excludeServiceIds', composeData.serviceDeActive)
      set(formData.serviceSettings, 'categoryIds', composeData.categoryActive)
    }

    const onCancel = () => router.push({ name: 'ProgressNotificationTargetTemplateList' })

    // 送出表單
    const onSubmit = async () => {
      const handler = async (data, { emit, loading }) => {
        let apiMethod
        if (!targetId.value) apiMethod = CreateProgressNotificationTargetTemplate
        if (targetId.value) apiMethod = UpdateProgressNotificationTargetTemplate
        const [, err] = await apiMethod(data)
        loading.value = false
        if (err) {
          window.$message.error(err)
          // return
        }
        router.push({ name: 'ProgressNotificationTargetTemplateList' })
        // emit('refresh')
        // emit('close')
      }
      await formSubmit(handler, submitData.value, { emit, loading })
    }

    const reservationServicesData = ref({})
    const getDataByPath = (sourceData, dataPath) => {
      return flatten(map(sourceData, item => get(item, dataPath)))
    }

    const categoryServiceDataList = computed(() => {
      return map(get(reservationServicesData.value, 'appointmentCategories', []), category => ({
        name: category.name,
        id: category.id,
        type: 'category',
        services: map(get(category, 'AppointmentServices'), service => ({
          category: category.id,
          name: service.name,
          id: service.id,
          type: 'service',
        })),
      }))
    })

    const serviceDataList = computed(() => {
      return map(get(reservationServicesData.value, 'appointmentServices', []), service => ({
        name: service.name,
        id: service.id,
        type: 'service',
        subServices: map(get(service, 'AppointmentSubServices'), subService => ({
          service: service.id,
          name: subService.name,
          id: subService.id,
          type: 'sub-service',
        })),
      }))
    })

    const attachServiceDataList = computed(() => {
      return map(get(reservationServicesData.value, 'appointmentServiceAttaches', []), service => ({
        name: service.name,
        id: service.id,
        // type: 'service',
      }))
    })

    onMounted(async () => {
      await simpleFetch(GetNotifyTargetTemplateAppointmentConfigOptions, { shopId: shopId.value }, (res) => {
        reservationServicesData.value = res
      })
      if (targetId.value) {
        simpleFetch(FindProgressNotificationTargetTemplate, { shopId: shopId.value, id: targetId.value },
          (res) => syncFormData(res),
        )
      }
    })

    return {
      reservationServicesData,
      loading,
      formRef,
      formData,
      formRules,
      onCancel,
      onSubmit,
      showSelector,
      targetTypeConfig,
      onCategoryServiceCompose,
      onCategoryServiceClear,
      submitData,
      categoryServiceDataList,
      serviceDataList,
      attachServiceDataList,
      useSubService,
      syncComposeServiceData,
      displayText,
    }
  },
})
</script>
